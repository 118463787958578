<template>
<section class="py-20 bg-gray-50">
  <div class="container mx-auto px-4">
    <div class="max-w-xl mx-auto">
      <div class="py-12 px-6 bg-white rounded shadow">
        <img class="mx-auto" src="../assets/pablo.png" alt="">
        <div class="text-center">
          <span class="mb-6 text-4xl text-green-600 font-bold">Whoops!</span>
          <h3 class="mb-2 text-4xl font-bold">Something went wrong!</h3>
          <p class="mb-8 text-gray-400">Sorry, but we are unable to open this page</p>
          <div><button class="w-full lg:w-auto mb-2 lg:mb-0 lg:mr-4 inline-block py-2 px-6 rounded-l-xl rounded-t-xl font-bold leading-loose text-gray-50 bg-green-600 hover:bg-green-700" @click="goToHomePage()">Go back to Homepage</button><button class="w-full lg:w-auto inline-block py-2 px-6 rounded-l-xl rounded-t-xl font-bold leading-loose bg-white hover:bg-gray-50" @click="reloadPage()">Try Again</button></div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'Not Found',
  methods: {
    reloadPage() {
      this.$router.go()
    },
    goToHomePage() {
      this.$router.push({ name: 'Home' })
    }
  },
}
</script>